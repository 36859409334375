"use client";

import React, { forwardRef, useRef } from "react";

import { AnimatedBeam } from "./AnimatedBeam.js";
import { cn } from "../../../utils/tailwind.js";
import { Headset } from "lucide-react"
import telegram from "../../../static/telegram.png"
import gmail from "../../../static/gmail.png"

const Circle = forwardRef(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-12 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className,
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export default function CustomerSupportBeam({ t }) {
  const containerRef = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div6Ref = useRef(null);

  return (
    <div
      className="w-full p-6 bg-[#AACADC] rounded-3xl shadow-lg overflow-hidden relative h-[450px] md:h-[427px]"
      // className="w-full max-w-md p-8 rounded-xl shadow-lg bg-white"
      ref={containerRef}
    >
      <div className="flex size-full flex-col max-w-lg max-h-[200px] items-stretch justify-between gap-10">
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-start mb-6">
            <h2 className="text-3xl font-bold mb-4 text-white">{t("customerSupport")}</h2>
            <Headset className="w-8 h-8 text-white" />
          </div>
          <p className="text-lg text-[#1B2D1A] opacity-80 mb-2">
            {t("customerSupportDescription")}
          </p>
        </div>
        <div className="flex size-full max-w-lg flex-row items-stretch justify-between gap-10">

        <div className="flex flex-col justify-center">
          <Circle ref={div6Ref} className="size-16">
            <Icons.user />
          </Circle>
        </div>
        <div className="flex flex-col justify-center gap-2">

            <Circle ref={div2Ref} className="p-2" >
            <img src={gmail} alt="Gmail" className="w-6 h-4" />

            </Circle>
            <Circle ref={div3Ref} className="p-2">
                <Icons.whatsapp />
            </Circle>
            <Circle ref={div4Ref} className="p-2">
              <img src={telegram} alt="Telegram" className="w-6 h-6" />
            </Circle>

        </div>
      </div>


      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div6Ref}
        duration={3}
        pathColor="white"
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div6Ref}
        duration={3}
        pathColor="white"
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div6Ref}
        duration={3}
        pathColor="white"
      />



    </div>
  );
}

const Icons = {
  gmail: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="52 42 88 66">
    <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6"/>
    <path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15"/>
    <path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2"/>
    <path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92"/>
    <path fill="#c5221f" d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"/>
    </svg>
  ),
   betSmarter: () => (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 311.11 598.47"
    >
      <defs>
        <style>
          {`.cls-1 {
            fill: #1B2D1A;
            stroke-width: 0px;
          }`}
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <polygon className="cls-1" points="94.73 115.62 1.32 171.15 .06 62.29 94.73 115.62"/>
          <polygon className="cls-1" points="97.24 .09 98.51 108.97 3.83 55.63 97.24 .09"/>
          <polygon className="cls-1" points="106.21 108.88 104.94 0 199.61 53.34 106.21 108.88"/>
          <polygon className="cls-1" points="110.13 115.44 203.53 59.91 204.8 168.78 110.13 115.44"/>
          <polygon className="cls-1" points="111.54 237.53 204.96 181.98 206.23 290.87 111.54 237.53"/>
          <polygon className="cls-1" points="212.51 168.69 211.23 59.81 305.9 113.16 212.51 168.69"/>
          <polygon className="cls-1" points="212.65 181.9 307.33 235.24 213.94 290.78 212.65 181.9"/>
          <polygon className="cls-1" points="216.43 175.24 309.84 119.71 311.11 228.6 216.43 175.24"/>
          <polygon className="cls-1" points="95.21 483 .96 428.92 1.39 537.8 95.21 483"/>
          <polygon className="cls-1" points="94.75 360.9 .47 306.82 .9 415.72 4.76 413.5 94.75 360.9"/>
          <polygon className="cls-1" points="0 184.74 .43 293.63 94.25 238.81 0 184.74"/>
          <polygon className="cls-1" points="99.53 598.47 99.1 489.58 5.27 544.39 99.53 598.47"/>
          <polygon className="cls-1" points="98.61 367.48 4.79 422.32 99.03 476.38 98.61 367.48"/>
          <polygon className="cls-1" points="98.55 354.29 98.12 245.39 4.3 300.22 98.55 354.29"/>
          <polygon className="cls-1" points="98.07 232.2 97.64 123.3 3.82 178.12 98.07 232.2"/>
          <polygon className="cls-1" points="106.81 489.55 107.23 598.44 201.06 543.63 106.81 489.55"/>
          <polygon className="cls-1" points="106.26 354.26 200.08 299.44 105.83 245.37 106.26 354.26"/>
          <polygon className="cls-1" points="110.62 482.93 204.87 537 204.44 428.11 110.62 482.93"/>
          <polygon className="cls-1" points="110.13 360.84 204.4 414.91 203.97 306.02 110.13 360.84"/>
          <polygon className="cls-1" points="212.15 428.08 212.58 536.97 306.4 482.15 212.15 428.08"/>
          <polygon className="cls-1" points="212.1 414.88 305.93 360.06 211.66 305.99 212.1 414.88"/>
          <polygon className="cls-1" points="215.96 421.47 310.23 475.54 309.8 366.64 215.96 421.47"/>
        </g>
      </g>
    </svg>
  ),
  user: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  ),
  whatsapp: () => (
    <svg
      width="100"
      height="100"
      viewBox="0 0 175.216 175.552"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="b"
          x1="85.915"
          x2="86.535"
          y1="32.567"
          y2="137.092"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#57d163" />
          <stop offset="1" stopColor="#23b33a" />
        </linearGradient>
        <filter
          id="a"
          width="1.115"
          height="1.114"
          x="-.057"
          y="-.057"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="3.531" />
        </filter>
      </defs>
      <path
        d="m54.532 138.45 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.523h.023c33.707 0 61.139-27.426 61.153-61.135.006-16.335-6.349-31.696-17.895-43.251A60.75 60.75 0 0 0 87.94 25.983c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.558zm-40.811 23.544L24.16 123.88c-6.438-11.154-9.825-23.808-9.821-36.772.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954zm0 0"
        fill="#b3b3b3"
        filter="url(#a)"
      />
      <path
        d="m12.966 161.238 10.439-38.114a73.42 73.42 0 0 1-9.821-36.772c.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954z"
        fill="#ffffff"
      />
      <path
        d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.559 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.524h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.929z"
        fill="url(#linearGradient1780)"
      />
      <path
        d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.313-6.179 22.558 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.517 31.126 8.523h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.928z"
        fill="url(#b)"
      />
      <path
        d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"
        fill="#ffffff"
        fillRule="evenodd"
      />
    </svg>
  ),

};
