import React from "react";
import { ConfigureModal } from "./ConfigureArbsModal.styled";
import { ConfigureArbsForm } from "./ConfigureArbsForm";

export const ConfigureArbsModal = React.memo(function ConfigureArbsModal({ show, setVisible }) {
  const close = () => {
    setVisible(false);
  };

  return (
    <ConfigureModal show={show} onHide={close} centered>
      <ConfigureModal.Header className="pb-0 border-bottom-0" closeButton>
        <ConfigureModal.Title>Configuración</ConfigureModal.Title>
      </ConfigureModal.Header>
      <ConfigureModal.Body className="pt-0">
        <ConfigureArbsForm closeModal={close}/>
      </ConfigureModal.Body>
    </ConfigureModal>
  );
});
