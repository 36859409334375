import React from 'react';
import { useTranslation } from 'react-i18next';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { Infinity } from "lucide-react"
import { motion, AnimatePresence, useInView } from "framer-motion"

import explainerVideo from "../../../static/home/explainer.lottie";

import {
  ExplainerSectionContainer,
  Container,
  Icon,
  Header,
  Title,
  Subtitle,
} from './ExplainerSection.styled.js';

export const ExplainerSection = ({ config }) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <ExplainerSectionContainer>
      <Container>
        <Header>
          <Icon>
            <Infinity className="w-14 h-14" />
          </Icon>
          <Title>
            {t('explainerTitle')}
          </Title>
          <Subtitle>
            {t('explainerSubtitle')}
          </Subtitle>
        </Header>
        <AnimatePresence>
          <motion.div
            ref={ref} // Attach the ref to the motion.div
            className="w-full p-6 bg-[#1F3A5F] rounded-3xl shadow-lg overflow-hidden relative"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: -20 }}
          >
            {isInView && (
              <DotLottiePlayer
                src={explainerVideo}
                autoplay
                loop={false}
                speed={0.9}
                style={{ width: '100%' }}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </Container>
    </ExplainerSectionContainer>
  );
};
