import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"; // Import framer-motion
import "./BotLanding.styled.js";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import logoWithName from "../../static/logoWithNameWhite.png";

import { FooterSection } from '../Home/FooterSection/FooterSection';
import { StatsSection } from "./StatsSection/StatsSection.js";
import { ArbsSection } from "./ArbsSection/ArbsSection.js";
import { ExplainerSection } from "./ExplainerSection/ExplainerSection.js";
import { NumbersSection } from "./NumbersSection/NumbersSection.js";
import { FeaturesSectionBot } from "./FeaturesSection/FeaturesSection.js";
import { useUser } from "../../hooks";
import {
  Container,
  SubContainer,
  Header,
  Logo,
  Main,
  Hero,
  HeroHeading,
  HeroSubHeading,
  NavDrop,
  HeroVideo,
  Video,
  Button,
  ContactText,
  SocialsContainer
} from './BotLanding.styled.js';

import ShimmerButton from "./ShimmerButton.js";

export const BotLanding = (props) => {
  const { t } = useTranslation();
  const { getBotHome } = useUser()
  const [config, setConfig] = useState(undefined);
  const [showContactButtons, setShowContactButtons] = useState(false); // New state for button toggle

  useEffect(() => {
    async function fetchBotHome() {
      const config = await getBotHome();
      setConfig(config);
    }
    fetchBotHome();
  }, []);

  return (
    <>
      <Helmet>
        <title>BetSmarter Bot</title>
        <meta name="description" content="Unlock the most powerful Arbitrage Bot" />
        <meta name="keywords" content="BetSmarter Bot, Arbitrage Bot, Betting Bot, Sports Betting, BetSmarter, arbitrage, betting, sports betting, bot, arbitraje deportivo, bot de arbitraje, bot de arbitraje deportivo, bot de arbitraje deportivo" />
        <meta name="author" content="BetSmarter" />
        <meta property="og:title" content="BetSmarter Bot" />
        <meta property="og:description" content="Unlock the most powerful Arbitrage Bot" />
        <meta property="og:image" content="https://betsmarter.app/bot-cover.jpg" />
        <meta name="theme-color" content="#003CB5" />
        <style>
          {`
            body {
              background-color: #003CB5;
            }
          `}
        </style>
      </Helmet>
      <Container>
        <SubContainer>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", delay: 0.7, duration: 1.5 }}
          >
            <Header>
              <Logo>
                <img src={logoWithName} alt="BetSmarter Logo" />
                <NavDrop title={i18n.language === 'en' ? '🇬🇧' : '🇪🇸'} id="language-dropdown">
                  <NavDrop.Item onClick={() => i18n.changeLanguage('en')}>
                    🇬🇧 {t('english')}
                  </NavDrop.Item>
                  <NavDrop.Item onClick={() => i18n.changeLanguage('es')}>
                    🇪🇸 {t('spanish')}
                  </NavDrop.Item>
                </NavDrop>
              </Logo>
            </Header>
          </motion.div>
          <Main>
            <Hero>
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  type: "spring",
                  delay: 0.25,
                  duration: 2.5,
                }}
                className="flex flex-col items-center justify-center"
              >
                <HeroHeading className="text-center">
                  {t('heroTitle')} <a style={{ color: "#E9FD8C" }}>{t('heroTitleAccent')}</a> {t('heroTitleBot')}
                </HeroHeading>
                {!showContactButtons ? (
                  <ShimmerButton
                    className="mt-6 px-8 py-3 text-[#1B2D1A] font-bold text-xl rounded-full hover:bg-[#1F3A5F] transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#1F3A5F] focus:ring-opacity-50 font-[Denton]"
                    onClick={() => setShowContactButtons(true)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    background="#FFF"
                    shimmerColor="#1F3A5F"
                  >
                    {t("botHeroButton")}
                  </ShimmerButton>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="flex flex-row items-center justify-center gap-4"
                  >
                    <motion.button
                      className="mt-6 px-8 py-3 border-2 border-[#25D366] text-white font-bold text-lg rounded-full hover:bg-[#1EBE5D] transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#25D366] focus:ring-opacity-50 flex items-center"
                      onClick={() => window.open('https://whatsapp.com/channel/0029Vaoxhjk5q08iLrU4Uj0R', '_blank')}
                    >
                      <span className="mr-2">WhatsApp</span>
                      <i className="fa-brands fa-whatsapp text-xl"></i>
                    </motion.button>
                    <motion.button
                      className="mt-6 px-8 py-3 border-2 border-[#0088cc] text-white font-bold text-lg rounded-full hover:bg-[#0077b5] transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#0088cc] focus:ring-opacity-50 flex items-center"
                      onClick={() => window.open('https://t.me/BetSmarterOfficial', '_blank')}
                    >
                      <span className="mr-2">Telegram</span>
                      <i className="fa-brands fa-telegram text-xl"></i>
                    </motion.button>
                  </motion.div>
                )}
              </motion.div>
            </Hero>
          </Main>
        </SubContainer>
      </Container>
      <ExplainerSection />
      <NumbersSection />
      <FeaturesSectionBot />
      <ArbsSection config={config}/>
      {/* <StatsSection config={config}/>
      <ArbsSection config={config}/>
      */}
      <FooterSection  backgroundColor="#ABCDDF" pattern={true} showLinks={false} showBotLinks={true} />
    </>
  );
};


