import React , { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import {
  CalculatorCardContainer,
  ReturnInput,
  CalculationsContainer,
  HeaderColumn,
  Header,
  PositiveValue,
  NegativeValue
} from './CalculatorCard.styled'

export const CalculatorCard = observer(({ arb, pinned, calculator }) => {
  const { t } = useTranslation();

  const getProfitBet1 = () => (calculator.getReturnForBet1(arb) - (calculator.mode === 'freebet' ? 0 : calculator.inputReturn));
  const getLossBet1 = () => calculator.mode === 'freebet' ? 0 : calculator.inputReturn;

  const getTotal1 = () => (getProfitBet1() - calculator.getStakeForBet2(arb));
  const getTotal2 = () => (calculator.getReturnForBet1(arb) - calculator.getStakeForBet2(arb)) - getLossBet1();

  return calculator?.calculatorEnabled && (
    <CalculatorCardContainer className={pinned ? "card pinned" : "card"} >
      <CalculationsContainer>
        <HeaderColumn>
          <p>_</p>
          <p>{t('betWonOn')} <b>{calculator.getBet1(arb).bookie.toUpperCase()}</b></p>
          <p>{t('betWonOn')} <b>{calculator.getBet2(arb).bookie.toUpperCase()}</b></p>
        </HeaderColumn>
        <HeaderColumn>
          <Header>{calculator.getBet1(arb).bookie.toUpperCase()}</Header>
          <PositiveValue>+{getProfitBet1().toFixed(2)}€</PositiveValue>
          <NegativeValue>-{getLossBet1().toFixed(2)}€</NegativeValue>
        </HeaderColumn>
        <HeaderColumn>
          <Header>{calculator.getBet2(arb).bookie.toUpperCase()}</Header>
          <NegativeValue>-{calculator.getStakeForBet2(arb).toFixed(2)}€</NegativeValue>
          <PositiveValue>+{(calculator.getReturnForBet1(arb) - calculator.getStakeForBet2(arb)).toFixed(2)}€</PositiveValue>
        </HeaderColumn>
        <HeaderColumn>
          <Header>TOTAL</Header>
          {getTotal1() >= 0 ? <PositiveValue>+{getTotal1().toFixed(2)}€</PositiveValue> : <NegativeValue>{getTotal1().toFixed(2)}€</NegativeValue>}
          {getTotal2() >= 0 ? <PositiveValue>+{getTotal2().toFixed(2)}€</PositiveValue> : <NegativeValue>{getTotal2().toFixed(2)}€</NegativeValue>}
        </HeaderColumn>
      </CalculationsContainer>
    </CalculatorCardContainer>
  );
});
