import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, useSpring, useInView } from "framer-motion"
import { History } from "lucide-react"
import { getSportIcon } from '../../../utils/events'


import {
  ArbsSectionContainer,
  Container,
  Icon,
  Header,
  Title,
  Subtitle,
  BetCardTable,
  Column,
  BetCardTitle,
  BetCardRow,
  RowContentContainer,
  BetDate,
  MatchOverview,
  Match,
  SportIcon,
  ClockIcon,
  ProfitBadgeText,
} from './ArbsSection.style.js';
import { AnimatedList } from './AnimatedList.js';

export const ArbsSection = ({ config }) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });
  
  return (
    <motion.div>
      <ArbsSectionContainer>
        <Container>
          <Header>
            <Icon>
              <History className="w-14 h-14" />
            </Icon>
            <Title>
              {t("lastArbitrages")}
            </Title>
            <Subtitle>
              {t("lastArbitragesSubtitle")}
            </Subtitle>
          </Header>
          <motion.div
            className="w-full max-w-10xl p-4 flex flex-col items-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 1 }}
            ref={ref}
          >
            <div className="w-full max-w-4xl h-[1050px]">
              <AnimatePresence inView={isInView}>
                <motion.div
                  className="space-y-4"
                >
                  <AnimatedList>
                    {config?.arbs?.map((bet, index) => (
                      <div
                        className={`rounded-3xl ${index % 2 === 0 ? 'bg-[#ABCDDF]' : 'bg-white'} text-[#1a2e1a] p-6 shadow-lg`}
                      >
                        <MatchOverview>
                          <Match>
                            <SportIcon src={getSportIcon(bet.event.sport)} />
                            {bet.event ? bet.event.name : bet.eventName}
                            <ProfitBadgeText className="badge badge-secondary">
                              {bet.pct.toFixed(2)}%
                            </ProfitBadgeText>
                          </Match>
                        </MatchOverview>
                        <BetDate>
                          <ClockIcon className="far fa-clock" />{" "}
                          {new Date(bet.timestamp).toLocaleString([], { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' })}
                        </BetDate>

                        <BetCardTable>
                          <Column small={true}>
                            <BetCardTitle>Bookies</BetCardTitle>
                            <RowContentContainer>
                              {bet.bets.map(bet => <BetCardRow>{bet.bookie}</BetCardRow>)}
                            </RowContentContainer>
                          </Column>
                          <Column className="column">
                            <BetCardTitle>{t('Name')}</BetCardTitle>
                            <RowContentContainer>
                              {bet.bets.map(bet => (
                                <BetCardRow small={true}>
                                  <a>{bet.name}</a>
                                </BetCardRow>
                              ))}
                            </RowContentContainer>
                          </Column>
                          <Column small={true}>
                            <BetCardTitle>{t('odds')}</BetCardTitle>
                            <RowContentContainer>
                              {bet.bets.map((bet) => (
                                <BetCardRow small={true}>
                                  {Math.round(bet.odds * 100) / 100}
                                </BetCardRow>
                              ))}
                            </RowContentContainer>
                          </Column>
                        </BetCardTable>
                      </div>
                    ))}
                  </AnimatedList>
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>

        </Container>
      </ArbsSectionContainer>
    </motion.div>
  );
};
