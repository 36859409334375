import styled from 'styled-components';
import pattern from '../../../static/home/pattern.png';
import Badge from "react-bootstrap/Badge";

export const FeaturesSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ABCDDF; // Assuming this is the background color you want
  padding: 80px 0 80px 0px;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  background-image: url(${pattern});
  background-size: ${Math.floor(Math.random() * 200 + 200)}px ${Math.floor(Math.random() * 200 + 200)}px;
  background-position: ${Math.random() < 0.5 ? 'center' : 'top left'};

  @media (max-width: 1455px) {
  @media (max-width: 1455px) {
    width: 100%;
    padding: 20px;
  }

`;


export const Container = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  height: 100%;
  width: 100%;
  max-width: 1600px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 23px;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -1px;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  `;

export const Subtitle = styled.h3`
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  letter-spacing: -1.5px;
  color: #ABCDDF;
  font-weight: 600;
  font-family: 'Denton';
  text-align: center;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

export const BookieBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  flex-grow: 1;
  font-size: 25px;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border: 1px solid #49575E;
  background-color: #B8DCEF;
  color: #1D2D1D;

`;

export const AnimatedBookieBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  flex-grow: 1;
  font-size: 25px;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border: 1px solid #49575E;
  background-color: #1d2d1d;
  color: #1D2D1D;

  animation: color-change ${() => `${Math.floor(Math.random()*10)+5}s`} infinite;

  @keyframes color-change {
    0% { background-color: #B8DCEF; }
    50% { 
      background-color: #E9FD8C;
    }
    100% { background-color: #B8DCEF; }
  }
`;
