import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, useSpring, useInView } from "framer-motion"
import { Sparkles, GalleryVerticalEnd, Zap, Search, Cloud } from "lucide-react"
import { observer } from "mobx-react-lite";

import RiskBeam from "./RiskBeam.js";
import SetUpCard from "./SetUpCard.js";
import CustomerSupportBeam from "./CustomerSupportBeam.js";
import CloudBasedBot from "./CloudBasedBot.js";
import BookiesCard from "./BookiesCard.js";

import {
  FeaturesSectionContainer,
  Container,
  Icon,
  Header,
  Title,
  Subtitle,
} from './FeaturesSection.style.js';


export const FeaturesSectionBot = ({ config }) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);

  return (
    <motion.div>
      <FeaturesSectionContainer>
        <Container>
          <Header>
            <Icon>
              <Sparkles className="w-14 h-14" />
            </Icon>
            <Title>
              {t("features")}
            </Title>
            <Subtitle>
              {t("featuresSubtitle")}
            </Subtitle>
          </Header>
          <motion.div
            className="w-full max-w-10xl p-4 flex flex-col lg:flex-row gap-4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 1 }}
            ref={ref}
          >
            <div className="w-full lg:w-1/2 flex flex-col gap-4">
              <SetUpCard t={t} />
              <BookiesCard t={t} />
            </div>
            <div className="w-full lg:w-1/2 flex flex-col gap-4">
              <AnimatePresence>
                <motion.div
                  className="space-y-4"
                >
                  <div className="flex flex-col md:flex-row gap-4">

                    <div className="flex-1">
                      <CloudBasedBot t={t} />
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  <RiskBeam t={t} />
                </div>
                <div className="flex-1">
                  <CustomerSupportBeam t={t} />
                </div>
              </div>
            </div>
          </motion.div>
        </Container>
      </FeaturesSectionContainer>
    </motion.div>
  );
};
