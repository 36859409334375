import styled from "styled-components";
import {getTextColorForBookie} from "../../utils/bookies";
import { Popover } from "react-bootstrap";

export const CardContainer = styled.div`
  width: 32%;
  min-width: 350px;
  border: 0px;
  margin-bottom: 1.5em;
  border-radius: 5px;
  display: block;
  background: #111e12;

  @media only screen and (max-width: 1200px) {
      width: 49%;
  }

  @media only screen and (max-width: 900px) {
      width: 100%;
  }

  @media only screen and (max-width: 330px) {
      min-width: 300px;
  }
`

export const BookieSettingsContainer = styled.div`
  display: flex;
  height: 27px;
  align-items: center;

  button {
    padding: 1px 7px 2px;
  }
`

export const CardHeaderCustom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.08);
  color: ${props => getTextColorForBookie(props.name)};
`

export const CardBody = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem;
`

export const BookieName = styled.a`
  font-weight: bold;
  color: #DAFF99;
  text-transform: capitalize;

  :hover{
    text-decoration: unset;
    color: white;
  }
`

export const InsideInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
`

export const InsideInfoTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #758B78;

  @media only screen and (max-width: 700px) {
    .insideInfoTitle {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
    }
  }
`

export const Balance = styled.div`
  height: 65px;
  width: 20%;
  display: flex;
  flex-direction: column;
`

export const NeedsDepositFooter = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #E9FD8C !important;
  color: #1d2d1d;
`

export const UsernameText = styled.a`
  padding: 5px;
  border-radius: 5px;
  margin-top: -5px;
  font-weight: bold;
  word-break: break-all;
  color: white;

  :hover{
    text-decoration: unset;
    color: white;
  }
`

export const StyledBadge = styled.div`
  margin-left: 5px; 
  background-color: #A33;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
`

export const PopOverBookie = styled(Popover)`
  background-color: #2A3B29;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 15px;

  .popover-header {
    background-color: #3B4B39;
    color: white;
    padding: 10px;
    border: 0px;
    border-radius: 15px 15px 0 0;
  }

  .popover-body {
    padding: 15px;
  }
`

export const PopoverGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const PopoverGridItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  background-color: #3B4B39;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  justify-content: space-between;

  p {
    margin: 0;
    font-size: 14px;
    color: #e0e0e0;
  }

  strong {
    color: #DAFF99;
  }
`

export const PopoverGridItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #DAFF99;
  height: 70px;
`

export const PopoverGridItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: right;
  font-weight: bold;
`

