"use client";

import React, { forwardRef, useRef } from "react";

import { AnimatedBeam } from "./AnimatedBeam.js";
import { cn } from "../../../utils/tailwind.js";
import { Brain } from "lucide-react"

const Circle = forwardRef(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-12 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className,
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export default function RiskBeam({ t }) {
  const containerRef = useRef(null);
  const div2Ref = useRef(null);
  const div4Ref = useRef(null);
  const div6Ref = useRef(null);

  return (
    <div
      className="w-full p-6 bg-[#1B2D1A] rounded-3xl shadow-lg overflow-hidden relative h-[427px]"
      ref={containerRef}
    >
      <div className="flex flex-col h-full items-center">
        <div className="flex flex-col items-start mb-6">
          <div className="flex justify-between items-start mb-6 w-full text-[#E9FD8C]">
            <h2 className="text-3xl font-bold mb-4">{t("minimizeRisks")}</h2>
            <Brain className="w-8 h-8" />
          </div>
          <p className="text-lg text-[#E9FD8C] opacity-80 mb-6">
            {t("minimizeRisksDescription")}
          </p>
        </div>
        <div className="flex-grow flex items-center justify-center w-full">
          <div className="flex flex-row items-center justify-around gap-10 w-full">
            <Circle ref={div2Ref} className="size-16">
              <div className="w-4">
                <Icons.betSmarter className="w-full h-full" />
              </div>
            </Circle>
            <Circle ref={div4Ref} className="size-16">
              <Icons.openai />
            </Circle>
            <Circle ref={div6Ref} className="size-16">
              <Icons.user />
            </Circle>
          </div>
        </div>
      </div>
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div4Ref}
        pathColor="#E9FD8C"
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div6Ref}
        pathColor="#E9FD8C"
      />
    </div>
  );
}

const Icons = {
  openai: () => (
    <svg
      width="100"
      height="100"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
    </svg>
  ),
   betSmarter: () => (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 311.11 598.47"
    >
      <defs>
        <style>
          {`.cls-1 {
            fill: #1B2D1A;
            stroke-width: 0px;
          }`}
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <polygon className="cls-1" points="94.73 115.62 1.32 171.15 .06 62.29 94.73 115.62"/>
          <polygon className="cls-1" points="97.24 .09 98.51 108.97 3.83 55.63 97.24 .09"/>
          <polygon className="cls-1" points="106.21 108.88 104.94 0 199.61 53.34 106.21 108.88"/>
          <polygon className="cls-1" points="110.13 115.44 203.53 59.91 204.8 168.78 110.13 115.44"/>
          <polygon className="cls-1" points="111.54 237.53 204.96 181.98 206.23 290.87 111.54 237.53"/>
          <polygon className="cls-1" points="212.51 168.69 211.23 59.81 305.9 113.16 212.51 168.69"/>
          <polygon className="cls-1" points="212.65 181.9 307.33 235.24 213.94 290.78 212.65 181.9"/>
          <polygon className="cls-1" points="216.43 175.24 309.84 119.71 311.11 228.6 216.43 175.24"/>
          <polygon className="cls-1" points="95.21 483 .96 428.92 1.39 537.8 95.21 483"/>
          <polygon className="cls-1" points="94.75 360.9 .47 306.82 .9 415.72 4.76 413.5 94.75 360.9"/>
          <polygon className="cls-1" points="0 184.74 .43 293.63 94.25 238.81 0 184.74"/>
          <polygon className="cls-1" points="99.53 598.47 99.1 489.58 5.27 544.39 99.53 598.47"/>
          <polygon className="cls-1" points="98.61 367.48 4.79 422.32 99.03 476.38 98.61 367.48"/>
          <polygon className="cls-1" points="98.55 354.29 98.12 245.39 4.3 300.22 98.55 354.29"/>
          <polygon className="cls-1" points="98.07 232.2 97.64 123.3 3.82 178.12 98.07 232.2"/>
          <polygon className="cls-1" points="106.81 489.55 107.23 598.44 201.06 543.63 106.81 489.55"/>
          <polygon className="cls-1" points="106.26 354.26 200.08 299.44 105.83 245.37 106.26 354.26"/>
          <polygon className="cls-1" points="110.62 482.93 204.87 537 204.44 428.11 110.62 482.93"/>
          <polygon className="cls-1" points="110.13 360.84 204.4 414.91 203.97 306.02 110.13 360.84"/>
          <polygon className="cls-1" points="212.15 428.08 212.58 536.97 306.4 482.15 212.15 428.08"/>
          <polygon className="cls-1" points="212.1 414.88 305.93 360.06 211.66 305.99 212.1 414.88"/>
          <polygon className="cls-1" points="215.96 421.47 310.23 475.54 309.8 366.64 215.96 421.47"/>
        </g>
      </g>
    </svg>
  ),
  user: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  ),

};
