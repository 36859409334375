import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, useSpring, useInView } from "framer-motion"
import { Wallet, UserIcon, Landmark, Handshake, ShieldPlus, HandCoins } from "lucide-react"
import GrowthCard from './GrowthCard';
import Ripple from "./Ripple";
import AnimatedNumber from './AnimatedNumber';

import {
  NumbersSectionContainer,
  Container,
  Icon,
  Header,
  Title,
  Subtitle,
} from './NumbersSection.style.js';

export const NumbersSection = ({ config }) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div>
      <NumbersSectionContainer>
        <Container>
          <Header>
            <Icon>
              <Handshake className="w-14 h-14" />
            </Icon>
            <Title>
              {t("figures")}
            </Title>
            <Subtitle>
              {t("figuresSubtitle")}
            </Subtitle>
          </Header>
          <motion.div
            className="w-full max-w-10xl p-4 flex flex-col lg:flex-row gap-4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 1 }}
            ref={ref}
          >
            <div className="w-full lg:w-1/2">
              <AnimatePresence>
                <motion.div className="space-y-4">
                  <div className="flex flex-col lg:flex-row gap-4">
                    <motion.div className="w-full lg:w-1/2 rounded-3xl bg-[#E8FC8B] text-[#1a2e1a] p-6 shadow-lg h-[160px]">
                      <div className="flex justify-between items-start mb-2">
                        <h2 className="text-2xl font-bold text-[#1a2e1a]">{t("totalProfit")}</h2>
                        <Wallet className="w-8 h-8 text-[#1a2e1a]" />
                      </div>

                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-5xl font-bold mb-1 lg:text-4xl xl:text-5xl">
                            <AnimatedNumber value={1208000} />€
                          </p>
                        </div>
                      </div>
                    </motion.div>
                    <motion.div className="w-full lg:w-1/2 p-6 bg-white rounded-3xl shadow-lg overflow-hidden relative h-[160px]">
                      <div className="flex justify-between items-start mb-2">
                        <h2 className="text-2xl font-bold text-[#1a2e1a]">{t("totalUsers")}</h2>
                        <UserIcon className="w-8 h-8 text-[#1a2e1a]" />
                      </div>

                      <div className="flex justify-between items-center">
                        <div>
                          <h2 className="text-5xl font-bold mb-1 lg:text-4xl xl:text-5xl text-[#1a2e1a]">
                            <AnimatedNumber value={450} />
                          </h2>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-4">
                  <div className="w-full lg:w-1/2">
                      <GrowthCard t={t}/>
                    </div>
                    <motion.div className="relative overflow-visible rounded-3xl bg-[#1a2e1a] text-[#E8FC8B] p-6 shadow-lg w-full lg:w-1/2 flex flex-col justify-between h-[350px]">
                      <div className="flex justify-between items-start mb-2 text-[#E9FB8B]">
                        <h2 className="text-2xl font-bold">{t("customerProfit")}</h2>
                        <HandCoins className="w-8 h-8" />
                      </div>
                      <div>
                        <p className="text-6xl font-bold mb-2">
                          <AnimatedNumber value={2500} />€
                        </p>
                        <p className="text-2xl">{t("customerProfitSubtitle")}</p>
                      </div>
                    </motion.div>
                  </div>
                  <motion.div className="w-full p-6 rounded-3xl bg-white text-[#1B2D1A] shadow-md space-y-4 overflow-y-auto" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                    <div className="flex justify-between items-start">
                      <h2 className="text-2xl font-bold text-[#1F3A5F]">Bank</h2>
                      <Landmark className="w-8 h-8 text-[#1F3A5F]" />
                    </div>
                    <div className="flex justify-between items-center text-white">
                      <div>
                        <p className="text-5xl font-bold text-[#1F3A5F]">
                          <AnimatedNumber value={2000} />€
                        </p>
                        <p className="text-xl text-[#1F3A5F]">{t("bankSubtitle")}</p>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </AnimatePresence>
            </div>
            <div className="w-full lg:w-1/2 space-y-4">
              <div className="relative bg-[#ABCDDF] flex h-[335px] w-full flex-col items-center justify-center overflow-hidden rounded-3xl bg-background md:shadow-xl">
                <p className="z-10 whitespace-pre-wrap text-center text-5xl font-medium tracking-tighter text-white">
                  <span className="text-[#E8FC8B]">Yield</span>
                  <br />
                  <span className="text-7xl font-bold"><AnimatedNumber value={8} />%</span>
                </p>
                <Ripple />
              </div>
              <div className="w-full bg-[#1F3A5F] p-6 rounded-3xl shadow-lg min-h-[365px] flex flex-col justify-between">
                <div className="flex justify-between items-start mb-2">
                  <h2 className="text-2xl font-bold text-white mb-6">{t("reliability")}</h2>
                  <ShieldPlus className="w-8 h-8 text-white" />
                </div>
                <div className="grid gap-6 lg:grid-cols-2">
                  <div className="bg-[#ABCDDF] p-4 rounded-xl flex flex-col justify-between">
                    <div>
                      <h3 className="text-lg font-bold text-[#1F3A5F] mb-2">{t("ourService")}</h3>
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-sm text-[#1F3A5F]">{t("singleBets")}</span>
                        <span className="px-2 py-1 text-xs font-semibold bg-[#94E5AB] text-[#1B2D1A] rounded-full">~0%</span>
                      </div>
                    </div>
                    <div className="w-full bg-[#1E395D] h-2 rounded-full mt-auto">
                      <div className="bg-[#94E5AB] h-2 rounded-full" style={{ width: '1%' }}></div>
                    </div>
                  </div>
                  <div className="bg-[#ABCDDF] p-4 rounded-xl flex flex-col justify-between">
                    <div>
                    <h3 className="text-lg font-bold text-[#1F3A5F] mb-2">{t("competitors")}</h3>
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-sm text-[#1F3A5F]">{t("singleBets")}</span>
                        <span className="px-2 py-1 text-xs font-semibold bg-[#f65151] text-white rounded-full">20-30%</span>
                      </div>
                    </div>
                    <div className="w-full bg-[#1E395D] h-2 rounded-full mt-auto">
                      {isInView && <motion.div className="bg-[#f65151] h-2 rounded-full" initial={{ width: 0 }} animate={{ width: '30%' }} transition={{ duration: 2, ease: "easeOut" }} whileInView={{ width: '30%' }} viewport={{ once: true }} ref={ref} />}
                    </div>
                  </div>
                </div>
                <p className="mt-6 text-lg text-white">
                  {t("reliabilityDescription")}
                </p>
              </div>
            </div>
          </motion.div>
        </Container>
      </NumbersSectionContainer>
    </motion.div>
  );
};
