import React, { useState, useEffect, Suspense, lazy } from "react";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { NavBarMain } from "..";
import { useTranslation } from "react-i18next";
import { PageContainer, Subcontainer } from './BotHome.styled';
import { useUsers } from "../../contexts/UsersContext";
import { useAuth, useBilling } from "../../hooks";

const HomeContent = lazy(() => import('./HomeContent'));

export function BotHome({ history }) {
  const { t } = useTranslation();
  const { userInfo, users } = useUsers();
  const { modifyUser } = useAuth();
  const { getTotalPending, getTotalPaidGross, compareAndSetPendingPayments, getTotal } = useBilling();

  const isAdmin = userInfo?.roles?.includes("admin");

  useEffect(() => {
    async function validateToken() {
      await checkIfTokenStillValid(history);
    }
    validateToken();
  }, [history]);

  return (
    <PageContainer>
      <NavBarMain currentPage="bot" type="dashboard" history={history} />
      <Subcontainer>
        <Suspense>
          {users &&
          <HomeContent
            t={t}
            users={users}
            userInfo={userInfo}
            isAdmin={isAdmin}
            modifyUser={modifyUser}
            getTotalPending={getTotalPending}
            getTotalPaidGross={getTotalPaidGross}
            compareAndSetPendingPayments={compareAndSetPendingPayments}
            getTotal={getTotal}
          />
          }
        </Suspense>
      </Subcontainer>
    </PageContainer>
  );
}
