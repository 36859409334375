import React, { useMemo } from "react";

import { motion } from "framer-motion";
import { GalleryVerticalEnd } from "lucide-react";
import { observer } from "mobx-react-lite";

import Marquee from './Marquee.js';

import {
    BookieBadge,
    AnimatedBookieBadge,
  } from './FeaturesSection.style.js';
  

export default function BookiesCard({ t }) {
    const bookies = [
        { label: 'Bet365' },
        { label: 'Winamax' },
        { label: 'Casumo' },
        { label: 'Paf' },
        { label: 'Retabet' },
        { label: 'Leovegas' },
        { label: 'Jokerbet' },
        { label: 'Tonybet' },
        { label: 'Bet777' },
        { label: 'Enracha' },
        { label: 'Daznbet' },
        { label: 'Admiralbet' },
        { label: 'Pokerstars' },
      ];
    
      const getArrayDivision = (array, division) => {
        const divisionSize = Math.floor(array.length / 3);
        const start = (division - 1) * divisionSize;
        const end = division * divisionSize;
        return array.slice(start, end);
      }
    
      const getBookieBadgeComponent = (bookie) => (
        Math.random() > 0.35 ? (
          <BookieBadge key={bookie} forwardedAs="label">
            {bookie.label || bookie.bookie}
          </BookieBadge>
        ) : (
          <AnimatedBookieBadge key={bookie} forwardedAs="label">
            {bookie.label || bookie.bookie}
          </AnimatedBookieBadge>
        )
      );
    
      const BookieMarquee = observer(({ bookies }) => {
        const bookieBadges = useMemo(() => {
          return new Array(3).fill(0).map((_, i) => (
            <Marquee pauseOnHover className="[--duration:20s] p-0" repeat={6}>
              {getArrayDivision(bookies, i + 1).flat().map((bookie) => (
                getBookieBadgeComponent(bookie)
              ))}
            </Marquee>
    
          ));
        }, [bookies]);
    
        return (
          <>
            {bookieBadges}
          </>
        );
      });

    return (
        <motion.div
        className="w-full"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-white rounded-3xl shadow-md h-[450px] md:h-[427px]">
          <div className="flex justify-between items-start mb-2 p-4 text-[#1B2D1A]">
            <h2 className="text-3xl font-bold pb-4">Bookies</h2>
            <GalleryVerticalEnd className="w-8 h-8" />
          </div>
          <p className="text-lg text-[#1B2D1A] opacity-80 mb-6 pl-4">
            {t("bookiesDescription")}
          </p>

          <div className="overflow-hidden pb-4 pt-4">
            <BookieMarquee bookies={bookies} />
          </div>
        </div>
      </motion.div>
    );
}