import React from "react";
import { observer } from "mobx-react-lite";
import { Field, useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import { Fieldset, LabelsContainer } from "./ConfigureArbsModal.styled";
import { HelpButton } from "../HelpButton/HelpButton";
import { useTranslation } from "react-i18next";

export const ConfigureArbsFormBookie = observer(({ bookie }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  // the values of this specific subscription
  const subscriptionValues = bookie === "default" ? values : values.bookiesConfigs[bookie];
  const namePrefix = bookie === "default" ? "" : `bookiesConfigs.${bookie}.`;

  // Do not show fields when using the default configuration
  if (subscriptionValues === undefined) return null;

  // Return the correct field name
  const fieldName = (name) => {
    return `${namePrefix}${name}`;
  };

  return (
    <Fieldset>
      <LabelsContainer>
      <Form.Label>{t('odds')}</Form.Label>
      <HelpButton id="modo-middle-help">
            {t('showOnlySureBetsInRange')}
      </HelpButton>
      <div style={{ display: "flex" }}>
        <Form.Group>
          <Form.Control
            as={Field}
            placeholder="Min"
            type="decimal"
            name={fieldName("minOdds")}
            min="0"
            max="100"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            as={Field}
            placeholder="Max"
            type="decimal"
            name={fieldName("maxOdds")}
            min="0"
            max="100000"
          />
        </Form.Group>
      </div>
      </LabelsContainer>
    </Fieldset>
  );
});
