'use client'

import React from 'react'
import { motion, AnimatePresence, useInView } from 'framer-motion'
import { CheckCircle, UserPlus, Key, EuroIcon, Cloud } from 'lucide-react'

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  hover: {}
}

export default function SetUpCard({ t }) {
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true })

  const steps = [
    { number: 1, title: t("register"), icon: UserPlus, color: "#FFF" },
    { number: 2, title: t("addCredentials"), icon: Key, color: "#AACADC" },
    { number: 3, title: t("startEarning"), icon: EuroIcon, color: "#E8FC8B" }
  ]

  return (
    <div ref={ref} className="w-full bg-[#1F3A5F] p-4 rounded-3xl shadow-lg h-auto md:h-[400px] flex flex-col justify-between">
      <div className="flex justify-between items-start mb-2">
        <h2 className="text-3xl font-bold pb-4 text-white">{t("easySetup")}</h2>
        <CheckCircle className="w-8 h-8 text-white" />
      </div>
      <div className="flex flex-col md:flex-row items-center mt-auto">
        <AnimatePresence>
          {steps.map((step, index) => (
            <React.Fragment key={step.number}>
              <motion.div
                className={`p-6 shadow-sm text-center relative w-full md:w-auto ${index === 0 ? 'max-md:rounded-t-xl md:rounded-l-xl' : index === steps.length - 1 ? 'max-md:rounded-b-xl md:rounded-r-xl' : ''}`}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                exit="hidden"
                variants={cardVariants}
                transition={{ duration: 0.5 }}
                whileHover="hover"
                style={{ backgroundColor: '#FFF', flex: 1, height: '200px' }}
              >
                <div className="absolute top-2 left-2 w-8 h-8 bg-[#1F3A5F] rounded-full flex items-center justify-center text-white text-lg font-bold">
                  {step.number}
                </div>
                <div className="flex flex-col items-center justify-center h-full">
                  <step.icon className="w-16 h-16 text-[#1F3A5F] mb-4" />
                  <h2 className="text-[#1F3A5F] text-xl font-bold mb-2 md:text-lg lg:text-xl">{step.title}</h2>
                </div>
              </motion.div>
              {index < steps.length - 1 && (
                <>
                  <div className="hidden md:block w-1 h-16 bg-gray-300"></div>
                  <div className="block md:hidden w-16 h-1 bg-gray-300"></div>
                </>
              )}
            </React.Fragment>
          ))}
        </AnimatePresence>
      </div>
    </div>
  )
}