import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const CalculatorCardContainer = styled.div`
  width: 100%;
  // height: 200px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  background: white;
  border: ${props => props.pinned ? '1px solid white' : ''};
`

export const ReturnInput = styled.input`
  font-weight: bold;
  font-size: medium;
  height: 26px;
  width: 70px;
  margin-left: 5px;
`

export const CalculationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #111e12;

  @media (max-width: 450px) {
    padding: 0px;
  }
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (max-width: 450px) {
    font-size: 14px;
    padding: 5px;
  }
`;

export const Header = styled.p`
  font-weight: bold;
  font-size: medium;
  color: #111e12;
  
  @media (max-width: 450px) {
      font-size: 14px;
    }
`;

export const PositiveValue = styled.p`
  font-weight: bold;
  font-size: medium;
  color: #17803d;
  height: 40%;
  display: flex;
  align-items: center;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const NegativeValue = styled.p`
  font-weight: bold;
  font-size: medium;
  color: #ff0000;
  height: 40%;
  display: flex;
  align-items: center;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;