import styled, { keyframes } from 'styled-components';
import Logo from "../../static/logo_white.svg";

// Keyframes for the fade-in animation
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center; 
  justify-content: space-between; // Change this line
  min-height: 100vh;
  padding: 20px; 
  background: #111E12; 
  color: white; 
  flex-direction: row; 
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center; // Center items on small screens
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const ConfirmationMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1s ease;
  max-width: 700px;
  width: 100%;
  box-sizing: border-box;
  font-size: 40px; // Set the font size as per design
  line-height: 1; // Set the line height for better readability
  // font-family: 'Montserrat', sans-serif;
`;

export const LogoContainer = styled.div`
  flex: 0 0 30%; // Do not grow, do not shrink, start at 30% of the container width
  max-width: 100%; // Ensure the image does not exceed its own width
  animation: ${fadeIn} 1.5s ease; // Fade-in animation for the logo
  @media (max-width: 768px) {
    display: none; // Hide on phones
  }

  img {
    max-height: 75vh; // Limit the height to half of the viewport height
    object-fit: contain; // Maintain aspect ratio while resizing
  }
`;