import styled from "styled-components";
import NavDropdown from 'react-bootstrap/NavDropdown';
import backgroundImage from '../../static/home/space_empty.png';
import pattern from '../../static/home/pattern.png';

// Styled components
export const Container = styled.div`
  display: flex;
  max-height: 1600px;
  width: 100%;
  justify-content: center;
  background-image: url(${pattern});
  background-size: 300px 300px;
  background-position: center;
`;

export const SubContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 1200px;
  max-width: 1600px;
  flex-direction: column;
  padding-bottom: 100px;
  background-image: url(${backgroundImage});
  // background-size: 125%; // Increased from 'cover' to zoom in
  background-position: center 80%; // Adjusted to focus more on the bottom
  background-repeat: no-repeat;
  height: 1200px;

  @media (max-width: 1440px) {
    width: 100%;
    background-size: 130%; // Further zoom for smaller screens
  }

  @media (min-width: 1500px) {
    max-height: 1100px;
    background-size: 110%; // Slightly less zoom for larger screens
  }

  @media (max-width: 768px) {
    background-image: none;
    height: 100%;
  }
`;

export const Header = styled.header`
  top: 0;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-top: 10px;
  }
  `;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 60px;
  }

  @media (max-width: 768px) {
    img {
      height: 30px; // Adjust this value as needed
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  gap: 20px;
`;

export const NavLinkSignup = styled.a`
  color: #1BB78A;

  border: 1px solid #1BB78A;
  z-index: 1;

  :hover {
    background: #1BB78A;
  }
`;

export const NavLinkLogin = styled.a`
  color: #111E12;

  background: white;
  z-index: 1;

  :hover {
  color: #111E12;
    background: #ffffdf;
  }
`;

// export const Main = styled.main`
//   display: flex;
//   flex-direction: row;
//   align-items: initial;
//   justify-content: space-between;
//   width: 100%;
//   max-width: 1600px;
//   margin-top: -100px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     margin-top: -100px;
//   }
// `;

export const Main = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 80px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: -100px;
  }
`;

export const Hero = styled.div`
  width: 100%;
  max-width: 1600px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  

@media (max-width: 768px) {
  text-align: center;
}
`;

export const HeroHeading = styled.h1`
  color: #fff;
  font-size: 80px;
  line-height: 1;
  margin: 0;
  font-family: 'Denton';
  text-align: center;

  @media (max-width: 768px) {
    font-size: 60px; // Adjust this value as needed
    margin-top: 200px; // Adjust this value as needed
  }

  @media (max-width: 348px) {
    font-size: 40px; // Adjust this value as needed
  }
`;

export const HeroSubHeading = styled.h2`
  color: #fff;
  font-weight: semi-bold;
  font-family: 'Montserrat';
  margin-bottom: 30px;
`;

export const ContactText = styled.h3`
  color: #fff;
  font-weight: semi-bold;
  font-family: 'Armstrong';
  margin-top: 50px;
`;

export const SocialsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 10px;
  }
`;

export const Button = styled.a`
  background-color: #1BB78A; // Use the green color from the design for the button
  color: #fff;
  border: none;
  padding: 15px 20px 10px 20px;
  font-size: 1.3em;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;

  font-family: 'Montserrat', sans-serif;
  font-weight: bold;

  &:hover {
    background-color: #16a34a;
  }

  .svg-inline--fa {
    height: 30px;
  }
`;

export const ImageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const HeroImage = styled.img`
  height: 1200px;
  object-fit: cover; // This will ensure the image covers the area without stretching

  @media (max-width: 1440px) {
    display: none; // This will hide the image on screens smaller than 768px
  }
`;

export const HeroShadow = styled(HeroImage)`
  position: absolute;
  transform: translateY(10px);
  filter: blur(10px);
`;

export const NavBarLink = styled.a`
  font-family: 'Montserrat';
  z-index: 1000;
  letter-spacing: -0.04em;
  font-size: 18px;
  position: relative;

  :hover {
    color: #1BB78A;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavDrop = styled(NavDropdown)`
  color: white;
  font-family: 'Montserrat';
  margin-bottom: 0;
  letter-spacing: -0.04em;
  font-size: 30px;
  z-index: 10;
  padding-top: 4px;

  @media (min-width: 768px) {
    padding-top: 8px;
  }

  a {
    color: ${props => props.inverted ? '#111E12' : 'white'};
    font-family: 'Montserrat';
    margin-bottom: 0;
    letter-spacing: -0.04em;
    font-size: 18px;
    font-weight: ${props => props.currentPage === 'arbspre' ? 'bold' : 'normal'};

    @media (max-width: 768px) {
      font-size: 14px;
    }

    :hover {
      color: #E9FD8C;
    }
  }

  .nav-link {
    padding: 0.6rem 1rem;
  }

  .dropdown-toggle {
    font-size: 22px;
  }

  .dropdown-item {
    font-weight: normal;
    color: white !important;

    :hover {
      background-color: #E9FD8C;
      color: #111E12 !important;
      font-weight: bold;
    }
  }

  .dropdown-menu {
    background-color: #1F3A5F;
    border: none;
  }
`;

export const HeroVideo = styled.div`
  padding: 0px 20px;
  max-width: 600px;
  width: 100%;
  height: 100%;
`;

export const Video = styled.div`
  padding: 28.12% 0px;
  height: 100%;
  position: relative;
`;