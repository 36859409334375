import React from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from 'react-i18next';

const termsByLanguage = {
  en: `
 <h1>Terms and Conditions for BetSmarter</h1>
<p><strong>Effective Date:</strong> 03/11/2024</p>

<h2>1. Introduction</h2>
<p>Welcome to BetSmarter, an automated tool for betting arbitrage (surebets). This service is exclusively available to users within Spain. By accessing or using BetSmarter, you agree to comply with these Terms and Conditions, so please read them carefully. This document outlines the terms governing the use of our service and includes important disclaimers, limitations of liability, and legal obligations.</p>

<h2>2. Eligibility</h2>
<p>To use BetSmarter, you must:</p>
<ul>
  <li>Be at least 18 years old.</li>
  <li>Be a resident of Spain and have a valid account with compatible bookmakers.</li>
  <li>Agree to comply with all applicable laws, as well as these Terms and Conditions.</li>
</ul>

<h2>3. Service Description</h2>
<p>BetSmarter automates the betting arbitrage process across compatible bookmakers. Users can purchase credit packages to access the bot’s functionality, which is intended to assist with arbitrage betting strategies. Projected returns on packages are estimates and not guaranteed, as betting outcomes are inherently uncertain.</p>

<h2>4. Account Registration and Security</h2>
<ul>
  <li><strong>Account Creation:</strong> Users must register with accurate and complete information to access BetSmarter. Failure to provide truthful information may result in service unavailability or termination.</li>
  <li><strong>Account Security:</strong> Users are responsible for safeguarding their account credentials and choosing strong passwords. BetSmarter disclaims liability for any unauthorized access or use of accounts.</li>
  <li><strong>Unauthorized Use:</strong> If you suspect unauthorized access or use of your account, you must inform us immediately at info@betsmarter.app. You are solely responsible for any activity conducted through your account, and we are not liable for losses or damages resulting from unauthorized access.</li>
</ul>

<h2>5. User Obligations</h2>
<p>By using BetSmarter, you agree to:</p>
<ul>
  <li><strong>Lawful Use:</strong> Use the bot in compliance with local gambling laws. The bot must only be used for its intended purpose.</li>
  <li><strong>Confidentiality:</strong> Keep all data, strategies, and insights derived from using BetSmarter confidential. You may not share, publish, or distribute this information without explicit permission from BetSmarter.</li>
  <li><strong>Bookmaker Accounts:</strong> You are responsible for managing your bookmaker accounts. Any bans, restrictions, or limitations imposed by bookmakers due to bot usage are your responsibility, and BetSmarter disclaims any associated liability.</li>
</ul>

<h2>6. Limitations of Liability</h2>
<ul>
  <li><strong>Service Provided "As-Is":</strong> BetSmarter is offered on an "as-is" basis. We do not guarantee specific results, performance, or uptime, and use of the bot is entirely at your own risk.</li>
  <li><strong>No Financial Responsibility:</strong> We are not responsible for any financial losses, missed betting opportunities, or issues resulting from bookmaker operations.</li>
  <li><strong>Disclaimer of Warranties:</strong> BetSmarter disclaims all warranties, express or implied, regarding the functionality or availability of its service. We do not warrant that the bot will be error-free, uninterrupted, or meet user expectations.</li>
</ul>

<h2>7. Credits, Refunds, and Transfers</h2>
<ul>
  <li><strong>Prepayment Credits:</strong> Use of BetSmarter requires purchasing prepaid credits. These credits enable access to the bot and are non-transferable outside of specified eligibility conditions.</li>
  <li><strong>Refund Policy:</strong> Credits are refundable within six months of purchase, minus a 10% processing fee. Refunds may take up to six months to process to ensure operational cash flow. Refund requests beyond the six-month period will not be accepted.</li>
  <li><strong>Credit Transfers:</strong> Credits can be transferred to other eligible users within Spain. Once transferred, these credits are non-refundable by either the original or receiving user.</li>
  <li><strong>Non-Transferable Refund Rights:</strong> Refunds are only available to the original purchaser of the credits; transferred credits are non-refundable.</li>
</ul>

<h2>8. Prohibited Uses and Conduct Restrictions</h2>
<ul>
  <li><strong>Prohibited Activities:</strong> Users must not reverse-engineer, tamper with, or otherwise misuse BetSmarter. Engaging in any of the following activities is strictly prohibited and may lead to account suspension or termination:
    <ul>
      <li>Falsifying eligibility, including age or residency requirements.</li>
      <li>Concealing identity or impersonating others.</li>
      <li>Using any automated processes, such as bots, for unauthorized data extraction or access.</li>
      <li>Spamming, distributing malware, or attempting to breach BetSmarter’s security.</li>
    </ul>
  </li>
  <li><strong>Respecting Bookmaker Terms:</strong> Users agree to use BetSmarter in compliance with bookmaker terms and policies, avoiding conduct that may violate these agreements or result in restricted access.</li>
</ul>

<h2>9. Intellectual Property</h2>
<ul>
  <li><strong>Ownership:</strong> All software, branding, materials, and intellectual property associated with BetSmarter are the exclusive property of BetSmarter. Users may not replicate, modify, or distribute these materials for unauthorized purposes.</li>
  <li><strong>Limited License:</strong> Users are granted a non-exclusive, limited license to use BetSmarter strictly for personal use, in accordance with these Terms. Any attempt to resell, sublicense, or transfer usage rights without permission is prohibited.</li>
</ul>

<h2>10. Indemnification</h2>
<p>By using BetSmarter, you agree to indemnify and hold harmless BetSmarter and its affiliates, officers, and employees from any claims or demands arising from your use of the bot, breach of these Terms, or infringement of third-party rights. This includes any legal costs or damages associated with such claims.</p>

<h2>11. Disclaimer of Warranties</h2>
<ul>
  <li><strong>No Guarantees:</strong> BetSmarter does not guarantee profits, winnings, or success in betting. Users acknowledge the inherent risks in betting, and that financial loss is possible.</li>
  <li><strong>No Liability for Bookmaker Actions:</strong> We are not liable for any actions, restrictions, or bans imposed by bookmakers as a result of using BetSmarter.</li>
  <li><strong>User Responsibility:</strong> Users agree that they are solely responsible for all outcomes of using the bot, including any financial gains or losses.</li>
</ul>

<h2>12. Governing Law and Jurisdiction</h2>
<p>These Terms are governed by the laws of Spain. Any disputes arising from or related to BetSmarter will be resolved exclusively in Spanish courts, in accordance with applicable Spanish laws.</p>

<h2>13. Modifications to Terms</h2>
<p>We reserve the right to amend these Terms and Conditions at any time. Notice of changes will be provided where feasible, but continued use of BetSmarter signifies acceptance of any modifications. Users may discontinue use if they do not accept the revised Terms.</p>

<h2>14. Service Interruption and Discontinuation</h2>
<ul>
  <li><strong>Service Downtime:</strong> We reserve the right to interrupt BetSmarter for maintenance, updates, or other changes. In case of a planned downtime, we will inform users in advance when possible.</li>
  <li><strong>Termination of Service:</strong> BetSmarter may suspend or terminate its service at its discretion. If the service is discontinued, users will retain any remaining prepaid credits, subject to our Refund Policy.</li>
</ul>

<h2>15. Contact Information</h2>
<p>For questions, concerns, or support, please contact us at info@betsmarter.app.</p>
`,
  es: `<h1>Términos y Condiciones para BetSmarter</h1>
<p><strong>Fecha de Vigencia:</strong> 03/11/2024</p>

<h2>1. Introducción</h2>
<p>Bienvenido a BetSmarter, una herramienta automatizada para la apuesta arbitrada (surebets). Este servicio está disponible exclusivamente para usuarios dentro de España. Al acceder o utilizar BetSmarter, usted acepta cumplir con estos Términos y Condiciones, por lo que le pedimos que los lea detenidamente. Este documento establece los términos que rigen el uso de nuestro servicio e incluye importantes cláusulas de exención de responsabilidad, limitaciones de responsabilidad y obligaciones legales.</p>

<h2>2. Elegibilidad</h2>
<p>Para utilizar BetSmarter, usted debe:</p>
<ul>
  <li>Tener al menos 18 años de edad.</li>
  <li>Ser residente de España y tener una cuenta válida con casas de apuestas compatibles.</li>
  <li>Aceptar cumplir con todas las leyes aplicables, así como con estos Términos y Condiciones.</li>
</ul>

<h2>3. Descripción del Servicio</h2>
<p>BetSmarter automatiza el proceso de apuesta arbitrada en casas de apuestas compatibles. Los usuarios pueden adquirir paquetes de créditos para acceder a la funcionalidad del bot, cuyo propósito es asistir en estrategias de apuestas arbitradas. Los retornos proyectados en los paquetes son estimaciones y no están garantizados, ya que los resultados de las apuestas son inherentemente inciertos.</p>

<h2>4. Registro de Cuenta y Seguridad</h2>
<ul>
  <li><strong>Creación de Cuenta:</strong> Los usuarios deben registrarse con información precisa y completa para acceder a BetSmarter. La falta de información veraz puede resultar en la indisponibilidad o terminación del servicio.</li>
  <li><strong>Seguridad de la Cuenta:</strong> Los usuarios son responsables de proteger sus credenciales de acceso y de elegir contraseñas seguras. BetSmarter no se hace responsable de ningún acceso no autorizado o uso de las cuentas.</li>
  <li><strong>Uso No Autorizado:</strong> Si sospecha de un acceso o uso no autorizado de su cuenta, debe informarnos inmediatamente en info@betsmarter.app. Usted es el único responsable de cualquier actividad realizada a través de su cuenta, y no somos responsables de pérdidas o daños derivados del acceso no autorizado.</li>
</ul>

<h2>5. Obligaciones del Usuario</h2>
<p>Al usar BetSmarter, usted acepta:</p>
<ul>
  <li><strong>Uso Legal:</strong> Usar el bot de acuerdo con las leyes locales de apuestas. El bot solo debe usarse para el propósito para el que fue diseñado.</li>
  <li><strong>Confidencialidad:</strong> Mantener toda la información, estrategias y conocimientos derivados del uso de BetSmarter en confidencialidad. No puede compartir, publicar ni distribuir esta información sin el permiso explícito de BetSmarter.</li>
  <li><strong>Cuentas de Casas de Apuestas:</strong> Usted es responsable de gestionar sus cuentas de casas de apuestas. Cualquier prohibición, restricción o limitación impuesta por las casas de apuestas debido al uso del bot es su responsabilidad, y BetSmarter no asume ninguna responsabilidad asociada.</li>
</ul>

<h2>6. Limitaciones de Responsabilidad</h2>
<ul>
  <li><strong>Servicio Proporcionado "Tal Cual":</strong> BetSmarter se ofrece "tal cual". No garantizamos resultados específicos, rendimiento o tiempo de actividad, y el uso del bot es completamente bajo su propio riesgo.</li>
  <li><strong>Sin Responsabilidad Financiera:</strong> No somos responsables de ninguna pérdida financiera, oportunidades de apuestas perdidas o problemas resultantes del funcionamiento de las casas de apuestas.</li>
  <li><strong>Exención de Garantías:</strong> BetSmarter rechaza todas las garantías, expresas o implícitas, con respecto a la funcionalidad o disponibilidad de su servicio. No garantizamos que el bot esté libre de errores, que funcione sin interrupciones o que cumpla con las expectativas del usuario.</li>
</ul>

<h2>7. Créditos, Reembolsos y Transferencias</h2>
<ul>
  <li><strong>Créditos de Prepago:</strong> El uso de BetSmarter requiere la compra de créditos prepagados. Estos créditos permiten el acceso al bot y no son transferibles fuera de las condiciones de elegibilidad especificadas.</li>
  <li><strong>Política de Reembolso:</strong> Los créditos son reembolsables dentro de los seis meses posteriores a la compra, menos una tarifa de procesamiento del 10%. Los reembolsos pueden tardar hasta seis meses en procesarse para garantizar el flujo de efectivo operativo. Las solicitudes de reembolso fuera del período de seis meses no serán aceptadas.</li>
  <li><strong>Transferencia de Créditos:</strong> Los créditos pueden ser transferidos a otros usuarios elegibles dentro de España. Una vez transferidos, estos créditos no son reembolsables ni por el usuario original ni por el usuario receptor.</li>
  <li><strong>Derechos de Reembolso No Transferibles:</strong> Los reembolsos solo están disponibles para el comprador original de los créditos; los créditos transferidos no son reembolsables.</li>
</ul>

<h2>8. Usos Prohibidos y Restricciones de Conducta</h2>
<ul>
  <li><strong>Actividades Prohibidas:</strong> Los usuarios no deben intentar ingeniería inversa, manipular o mal utilizar BetSmarter. Participar en cualquiera de las siguientes actividades está estrictamente prohibido y puede resultar en la suspensión o terminación de la cuenta:
    <ul>
      <li>Falsificar la elegibilidad, incluyendo requisitos de edad o residencia.</li>
      <li>Ocultar su identidad o hacerse pasar por otros.</li>
      <li>Utilizar procesos automatizados, como bots, para extracción o acceso no autorizado de datos.</li>
      <li>Hacer spam, distribuir malware o intentar violar la seguridad de BetSmarter.</li>
    </ul>
  </li>
  <li><strong>Respetar los Términos de las Casas de Apuestas:</strong> Los usuarios aceptan utilizar BetSmarter de acuerdo con los términos y políticas de las casas de apuestas, evitando conductas que puedan violar estos acuerdos o resultar en acceso restringido.</li>
</ul>

<h2>9. Propiedad Intelectual</h2>
<ul>
  <li><strong>Propiedad:</strong> Todo el software, la marca, los materiales y la propiedad intelectual asociados con BetSmarter son propiedad exclusiva de BetSmarter OÜ. Los usuarios no pueden replicar, modificar o distribuir estos materiales para propósitos no autorizados.</li>
  <li><strong>Licencia Limitada:</strong> Se concede a los usuarios una licencia no exclusiva y limitada para utilizar BetSmarter estrictamente para uso personal, de acuerdo con estos Términos. Cualquier intento de revender, sublicenciar o transferir los derechos de uso sin permiso está prohibido.</li>
</ul>

<h2>10. Indemnización</h2>
<p>Al usar BetSmarter, usted acepta indemnizar y mantener indemne a BetSmarter y a sus afiliados, funcionarios y empleados de cualquier reclamo o demanda que surja de su uso del bot, incumplimiento de estos Términos o infracción de derechos de terceros. Esto incluye cualquier costo legal o daño asociado con dichos reclamos.</p>

<h2>11. Exención de Garantías</h2>
<ul>
  <li><strong>Sin Garantías:</strong> BetSmarter no garantiza ganancias, éxito en las apuestas ni beneficios. Los usuarios reconocen los riesgos inherentes en las apuestas y que la pérdida financiera es posible.</li>
  <li><strong>Sin Responsabilidad por Acciones de las Casas de Apuestas:</strong> No somos responsables de las acciones, restricciones o prohibiciones impuestas por las casas de apuestas como resultado del uso de BetSmarter.</li>
  <li><strong>Responsabilidad del Usuario:</strong> Los usuarios aceptan que son los únicos responsables de todos los resultados del uso del bot, incluidas ganancias o pérdidas financieras.</li>
</ul>

<h2>12. Ley Aplicable y Jurisdicción</h2>
<p>Estos Términos se rigen por las leyes de España. Cualquier disputa derivada de o relacionada con BetSmarter se resolverá exclusivamente en los tribunales españoles, de acuerdo con las leyes aplicables en España.</p>

<h2>13. Modificaciones a los Términos</h2>
<p>Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Se proporcionará notificación de cambios cuando sea posible, pero el uso continuado de BetSmarter implica la aceptación de cualquier modificación. Los usuarios pueden dejar de usar el servicio si no aceptan los Términos revisados.</p>

<h2>14. Interrupción y Discontinuación del Servicio</h2>
<ul>
  <li><strong>Tiempo de Inactividad del Servicio:</strong> Nos reservamos el derecho de interrumpir BetSmarter para mantenimiento, actualizaciones u otros cambios. En caso de una interrupción planificada, informaremos a los usuarios con anticipación cuando sea posible.</`,
};

export function BotTerms() {
  const { i18n } = useTranslation();

  // Get the current language
  const currentLanguage = i18n.language;

  return (
    <div className="min-h-screen bg-[#111E12]">
      <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="bg-white shadow-sm rounded-xl p-6 sm:p-8">
          <div className="prose prose-sm sm:prose lg:prose-lg mx-auto">
            {ReactHtmlParser(termsByLanguage[currentLanguage], {
              replace: (node) => {
                if (node.type === 'tag') {
                  // Style headings
                  if (node.name === 'h1') {
                    node.attribs.class = 'text-2xl sm:text-3xl font-bold text-gray-900 mb-4 mt-8';
                  }
                  // Style subheadings
                  if (node.name === 'h2' || node.name === 'h3') {
                    node.attribs.class = 'text-xl sm:text-2xl font-semibold text-gray-800 mb-3 mt-6';
                  }
                  // Style paragraphs
                  if (node.name === 'p') {
                    node.attribs.class = 'text-gray-600 mb-4 leading-relaxed';
                  }
                  // Style lists
                  if (node.name === 'ul' || node.name === 'ol') {
                    node.attribs.class = 'list-disc ml-6 mb-4 text-gray-600 space-y-2';
                  }
                  // Style list items
                  if (node.name === 'li') {
                    node.attribs.class = 'text-gray-600';
                  }
                  // Style links
                  if (node.name === 'a') {
                    node.attribs.class = 'text-blue-600 hover:text-blue-800 underline';
                  }
                  // Style horizontal rules
                  if (node.name === 'hr') {
                    node.attribs.class = 'my-8 border-t border-gray-200';
                  }
                  // Style strong tags
                  if (node.name === 'strong') {
                    node.attribs.class = 'font-semibold text-gray-900';
                  }
                }
                return undefined;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
