import React from "react"
import { Cloud, Zap, Search } from "lucide-react"

export default function CloudBasedBot({ t }) {
    return (
        <div className="w-full bg-white rounded-3xl shadow-lg overflow-hidden h-[430px] md:h-[316px]">
            <div className="p-6 h-full flex flex-col">
                <div className="flex justify-between items-start mb-6 w-full text-[#1F3A5F]">
                    <h2 className="text-3xl font-bold mb-4">{t("cloudBased")}</h2>
                    <Cloud className="w-8 h-8" />
                </div>
                <p className="text-lg text-[#1F3A5F] opacity-80 mb-2">
                            {t("cloudBasedDescription")}
                </p>
                <div className="flex-grow flex flex-col md:flex-row space-between space-y-4 md:space-y-0 md:space-x-8 pt-4 md:pt-0">
                    <div className="flex items-center space-x-4 w-full md:w-1/2">
                        <div className="bg-[#1F3A5F] rounded-full p-3">
                            <Search className="h-8 w-8 text-[#E9FD8C]" />
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-[#1F3A5F] mb-1">{t("realTimeScanning")}</h3>
                            <p className="text-sm text-[#1F3A5F]">{t("realTimeScanningDescription")}</p>
                        </div>
                    </div>

                    <div className="flex items-center space-x-4 w-full md:w-1/2">
                        <div className="bg-[#1F3A5F] rounded-full p-3">
                            <Zap className="h-8 w-8 text-[#E9FD8C]" />
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold text-[#1F3A5F] mb-1">{t("fastExecution")}</h3>
                            <p className="text-sm text-[#1F3A5F]">{t("fastExecutionDescription")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
