import React from 'react'
import {
  ResponsiveContainer,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import { motion, useInView } from 'framer-motion'
import { ChartNoAxesCombined } from "lucide-react"

const data = [
  { week: 'Week 0', profit: 0 },
  { week: 'Week 1', profit: 400 },
  { week: 'Week 2', profit: 1200 },
  { week: 'Week 3', profit: 2500 },
]

export default function GrowthCard({ t }) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
      return (
        <div className="bg-[#1F3A5F] p-2 rounded-md shadow-md">
          <p className="text-[#E8FC8B] font-bold">{t("week")} {label}</p>
          <p className="text-white font-bold">{payload[0].value}€</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full overflow-hidden bg-gradient-to-br bg-[#ABCDDF] text-white shadow-lg rounded-3xl h-[350px]">
      <motion.div
        ref={ref}
      >
        <div className="p-6 pb-2">
          <div className="flex justify-between items-start mb-2 text-white">
            <h2 className="text-2xl font-bold">{t("yourTotalProfit")}</h2>
            <ChartNoAxesCombined className="w-8 h-8" />
          </div>
          <p className="text-[#1B2D1A] text-2xl">{t("profitTrend")}</p>
        </div>
      </motion.div>
      {isInView && (
        <motion.div>
          <div className="p-0">
            <div className="h-[250px] w-full">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart height={250} data={data}
                  margin={{ top: 10, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#E8FC8B" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#E8FC8B" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Tooltip content={<CustomTooltip />} />
                  <Area type="monotone" dataKey="profit" stroke="#E8FC8B" fillOpacity={1} fill="url(#colorUv)" dot={{ stroke: '#E8FC8B', strokeWidth: 2, r: 4 }} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  )
}