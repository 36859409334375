import React, { useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _uniq from "lodash/uniq";
import _without from "lodash/without";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Form as FormikForm, Formik } from "formik";
import { useTranslation } from 'react-i18next';

import { getSubscriptions } from "../../utils/subscriptions";
import { SeparatorWrapper, LabelSelectAll, BookieBadge, WideBadge, BookiesContainer, LabelsContainer, BookieSearchContainer, BookieListContainer, ClonesContainer, MainBookieContainer, Clone2Container } from "./ConfigureValuebetModal.styled";
import { ConfigureValuebetFormSubscription } from "./ConfigureValuebetFormSubscription";
import { ConfigureValuebetFormBookie } from "./ConfigureValuebetFormBookie";
import { ConfigureValuebetSettings } from "./ConfigureValuebetSettings";
import { HelpButton } from "../HelpButton/HelpButton";
import { DisabledBookieCircle } from "../DisabledBookieCircle/DisabledBookieCircle";
import { useSettings } from "../../hooks";

function copySubscriptionConfiguration(selectedSubscription, selectedSubscriptionToCopy, formik) {
  const config = formik.values.subscriptionConfigs[selectedSubscriptionToCopy];
  formik.setFieldValue(`subscriptionConfigs.${selectedSubscription}`, config);
}

export const ConfigureValuebetForm = observer(({ closeModal }) => {
  const settingsRef = useRef();
  const [formLoading, setFormLoading] = useState(false);
  const [bookiesByCountry, setBookiesByCountry] = useState("*");
  const [searchBookies, setSearchBookies] = useState("");
  const [showClones, setShowClones] = useState(false);
  const { options, schema, settings, isLive, isValuebetsPre } = useSettings();
  const configuration = settings?.configuration;
  const [selectedBookies, setSelectedBookies] = useState(configuration.bookies);
  const { t } = useTranslation();

  const availableCountries = [
    {
      value: "ES",
      label: t('Spain'),
    },
    {
      value: "UK",
      label: t('UK'),
    },
    {
      value: "MX",
      label: "Mexico",
    }
  ]


  function isBookieVisible(bookie, bookiesByCountry) {
    // filter bookies by country
    return (
      bookiesByCountry === "*" ||
      (bookiesByCountry === "O" && !_isEmpty(_without(bookie.countries, "ES"))) ||
      (availableCountries.some(country => country.value === bookiesByCountry) && (bookie.countries.includes(bookiesByCountry) || (bookie.clones || []).some(clone => clone.countries.includes(bookiesByCountry))))
    );
  }

    // Select / deselect all bookies (only affects the array of passed bookies)
  function onSelectAllBookies(event, formik, affectedBookies) {
    const { checked } = event.target; // the value of checked has already changed by the click
    const { bookies = [] } = formik.values;
    let newBookies;
    if (checked) {
      newBookies = _uniq(bookies.concat(affectedBookies.map((bookie) => bookie.bookie)));
      formik.setValues(
        {
          ...formik.values,
          bookies: newBookies,
        },
        false
      );
    } else {
      newBookies = bookies.filter(
        (bookieName) => !affectedBookies.find((visibleBookie) => bookieName === visibleBookie.bookie)
      );
      formik.setValues(
        {
          ...formik.values,
          bookies: newBookies,
        },
        false
      );
    }
    setSelectedBookies(newBookies); // update the selectedBookies state
  }

  const visibleBookies = useMemo(
    () => options.bookies
    .filter((bookie) => 
      (bookie.removed ? selectedBookies.some(_bookie => _bookie === bookie.bookie) : true ) &&
      (searchBookies.length > 0 ? true : isBookieVisible(bookie, bookiesByCountry)) && 
      (bookie.label.toLowerCase().includes(searchBookies.toLowerCase()) || (bookie.clones || []).some(({ bookie }) => bookie.toLowerCase().includes(searchBookies.toLocaleLowerCase())))
    ),
    [options.bookies, bookiesByCountry, searchBookies, showClones, selectedBookies]
  );

  function onBookieChange(event, formik) {
    const { name: bookie } = event.target;
    let { bookies = [], subscriptionConfigs = {} } = formik.values;
     if (bookies.includes(bookie)) {
      bookies = _without(bookies, bookie);
      delete subscriptionConfigs[bookie];
      formik.setFieldValue("bookies", bookies, false);
      formik.setFieldValue("subscriptionConfigs", subscriptionConfigs, false);
    } else {
      bookies = bookies.concat(bookie);
      formik.setFieldValue("bookies", bookies, false);
    }
    setSelectedBookies(bookies); // update the selectedBookies state
  }

  const getSettingsFromFormikValues = (values) => {
    // discard fields from the form that are not saved in the configuration
    const newConfiguration = _omit(values, ["selectedSubscription", "subscriptionToCopy", "selectedBookie"]);
    return newConfiguration;
  };

  // Return the subscriptions formatted and sorted to be used as options in a select
  const getSubscriptionsForOptions = (configuration) => {
    const subscriptions = getSubscriptions(configuration);
    const subscriptionsForOptions = subscriptions
      .map((subscription) => {
        let text = options.bookieToLabel[subscription];
        if (configuration.subscriptionConfigs[subscription] !== undefined) text = "⚙️ " + text;

        return { subscription, text };
      })
      .sort(({ subscription: a }, { subscription: b }) => a.localeCompare(b));

    return { subscriptions, subscriptionsForOptions };
  };

  // This corresponds to the "Guardar" button
  const onFormSubmit = async (values) => {
    const newSettings = getSettingsFromFormikValues(values);
    settingsRef.current.saveSettings(newSettings);
  };

  function WideBadgeComponent({ bookie, label, disabled, removed, checked, onBookieChange, formik, nonSelectable, nonClone }) {
    return (
      <BookieBadge key={bookie} checked={checked} forwardedAs="label" nonClone={nonClone}>
        <input
          type="checkbox"
          name={bookie}
          checked={checked}
          value={checked}
          onChange={(event) => onBookieChange(event, formik)}
          disabled={nonSelectable}
        />{" "}
        {label}
        { disabled && <DisabledBookieCircle id="bookies-help" placement="top" width="140px">
          La bookie se encuentra desactivada temporalmente
        </DisabledBookieCircle> }
        { removed && <DisabledBookieCircle id="bookies-help" placement="top" width="140px" color="black">
          La bookie se encuentra eliminada
        </DisabledBookieCircle> }
      </BookieBadge>
    );
  }

  const getCloneFilter = (clone, bookie) => 
    (clone.removed ? selectedBookies.some(_bookie => _bookie === `${bookie}:${clone.bookie}`) : true ) &&
    (searchBookies.length > 0 ? true : (bookiesByCountry === '*' ? true : (clone.countries.includes(bookiesByCountry) || (bookiesByCountry === 'O' && clone.countries.every(country => !availableCountries.map(({ value }) => value).includes(country)))))) &&
    (clone.label.toLowerCase().includes(searchBookies.toLowerCase()));

  return (
    <Formik initialValues={configuration} onSubmit={onFormSubmit} enableReinitialize>
      {(formik) => {
        const { bookies = [] } = formik.values;
        const { subscriptions, subscriptionsForOptions } = getSubscriptionsForOptions(formik.values);
        let selectedSubscription = formik.values.selectedSubscription;
        if (!subscriptions.includes(selectedSubscription)) selectedSubscription = "default";
        const isSelectAllBookiesChecked = useMemo(
          () => visibleBookies.every((visibleBookie) => bookies.includes(visibleBookie.bookie)),
          [bookies, visibleBookies]
          );
          
        let selectedBookie = formik.values.selectedBookie;
        if (!bookies.includes(selectedBookie)) selectedBookie = "default";

        const onUseDefaultSubscriptionConfigChange = (event, subscription) => {
          const config = event.target.checked ? undefined : schema.defaultSubscriptionConfig;
          formik.setFieldValue(`subscriptionConfigs.${subscription}`, config);
        };

        return (
          <Form as={FormikForm}>
            <fieldset disabled={formLoading}>
              <SeparatorWrapper className="mb-1">
                <hr />
                <Form.Label style={{ fontWeight: "bold" }}>{t('globalConfiguration')}</Form.Label>
                <hr />
              </SeparatorWrapper>

              <BookiesContainer className="bookies-title" style={{ marginBottom: "10px" }}>
                <Form.Label>Bookies</Form.Label>
                <HelpButton id="bookies-help" placement="right-start" width="340px">
                  Selecciona que bookies están presentes en los arbitrajes. Puedes cambiar el estado de cada bookie
                  haciendo click.
                  <br />
                  Hay tres posibles estados:
                  <ul>
                    <li>Seleccionadas en color verde: estas bookies puede que aparezcan en el arbitraje</li>
                    <li>Seleccionadas en color morado: al menos una de estas bookies tiene que estar en el arbitraje</li>
                    <li>No seleccionadas: no salen en los arbitrajes</li>
                  </ul>
                </HelpButton>
                <LabelSelectAll>
                  <input
                    type="checkbox"
                    name="all-bookies"
                    checked={isSelectAllBookiesChecked}
                    onChange={(event) => onSelectAllBookies(event, formik, visibleBookies)}
                  />{" "}
                  {t('selectAll')}
                </LabelSelectAll>
                <Form.Control
                  as="select"
                  label="Bookies por país"
                  name="bookiesByCountry"
                  onChange={({ target }) => setBookiesByCountry(target.value)}
                  value={bookiesByCountry}
                  style={{ display: "inline", width: "auto", height: 33.25, marginLeft: '10px' }}
                >
                  <option value="*">{t('allBookies')}</option>
                  {
                    availableCountries.map(({ value, label }) => (
                      <option value={value}>{label}</option>
                    ))
                  }
                  <option value="O">{t('otherCountries')}</option>
                </Form.Control>
                <LabelSelectAll>
                  <input
                    type="checkbox"
                    name="all-clones"
                    checked={showClones}
                    onChange={() => setShowClones(prevShowClones => !prevShowClones)}                  
                  />{" "}
                  {t('showClones')}
                </LabelSelectAll>
                <BookieSearchContainer>
                <Form.Label>{t('search')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t('filterBookies')}
                    onChange={({ target }) => setSearchBookies(target.value)}
                  />
                </BookieSearchContainer>
                <BookieListContainer role="group" aria-labelledby="checkbox-group" style={{ marginTop: '10px' }}>
                  {visibleBookies
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .filter(({ removed, bookie }) => !removed || bookies.includes(bookie))
                    .map(({ bookie, label, disabled, removed, clones }) => {
                      const checked = bookies.includes(bookie);
                      const nonSelectable = selectedBookies.some(_bookie => _bookie.includes(bookie) && _bookie !== bookie);

                      return clones && (showClones || nonSelectable) ? (<>
                        <ClonesContainer>
                          <MainBookieContainer>
                            <WideBadgeComponent
                              bookie={bookie}
                              label={label}
                              disabled={disabled}
                              removed={removed}
                              checked={checked}
                              nonSelectable={nonSelectable}
                              onBookieChange={onBookieChange}
                              formik={formik}
                            />
                          </MainBookieContainer>
                          { clones
                              .filter(clone => getCloneFilter(clone, bookie)).length > 0 && <Clone2Container>
                            {
                              clones
                              .filter(clone => getCloneFilter(clone, bookie))
                              .map(clone => {
                                const removed = clone.removed;
                                const _bookie = `${bookie}:${clone.bookie}`;

                                const checked = bookies.includes(_bookie);

                                const mainBookie = bookie.split(":")[0];
                                const nonSelectable = (!checked) && selectedBookies.some(_bookie => _bookie.includes(mainBookie));

                                return <WideBadgeComponent
                                  bookie={_bookie}
                                  label={clone.label}
                                  disabled={disabled}
                                  removed={removed}
                                  checked={checked}
                                  nonSelectable={nonSelectable}
                                  onBookieChange={onBookieChange}
                                  formik={formik}
                                />
                              })
                            }</Clone2Container> }

                        </ClonesContainer>
                      </>) : (
                        <MainBookieContainer nonClone={true}>
                          <WideBadgeComponent
                            bookie={bookie}
                            label={label}
                            disabled={disabled}
                            removed={removed}
                            checked={checked}
                            onBookieChange={onBookieChange}
                            formik={formik}
                            nonClone={true}
                          />
                        </MainBookieContainer>
                      );
                    })}
                </BookieListContainer>
              </BookiesContainer>
              <Form.Group controlId="sortBy">
                <LabelsContainer>
                <Form.Label>{t('sortBy')}</Form.Label>
                <Form.Control
                  name="sortBy"
                  as="select"
                  label="Ordena Por"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sortBy}
                >
                  {options.sortings.map(({ type, text }) => (
                    <option key={type} value={type}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
                </LabelsContainer>
              </Form.Group>
              { isValuebetsPre && <Form.Group controlId={"mode"}>
              <LabelsContainer>
              <Form.Label>Modo visualización</Form.Label>
              <HelpButton id="leagues-help" placement="right-start" width="340px">
                Selecciona el modo en el que quieres ver los arbitrajes.
              </HelpButton>
              <Form.Control
                name={"mode"}
                onChange={formik.handleChange}
                as="select"
                value={formik.values.mode}
                label="Modo visualización"
              >
                <option value={'normal'}>Normal</option>
                <option value={'freebet'}>Freebet</option>
              </Form.Control>
              </LabelsContainer>
            </Form.Group> }
              { isValuebetsPre && <Form.Group controlId={"onlyLiquid"}>
              <LabelsContainer>
              <Form.Label>Mostrar solo eventos líquidos</Form.Label>
              <HelpButton id="leagues-help" placement="right-start" width="340px">
                Activalo si quieres ver solo eventos de grandes ligas.
              </HelpButton>
              <Form.Control
                name={"onlyLiquid"}
                onChange={formik.handleChange}
                as="select"
                value={formik.values.onlyLiquid}
                label="Mostrar solo eventos líquidos"
              >
                <option value={true}>Activar</option>
                <option value={false}>Desactivar</option>
              </Form.Control>
              </LabelsContainer>
            </Form.Group> }
             { (isLive || isValuebetsPre ) && <>
              <SeparatorWrapper className="mb-1">
                <hr />
                <Form.Label style={{ fontWeight: "bold" }}>Configuración por bookie</Form.Label>
                <hr />
              </SeparatorWrapper>
              <Form.Group>
                <Form.Control
                  as="select"
                  label="Configuración por arbitraje"
                  name="selectedSubscription"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={selectedSubscription}
                  style={{ background: "#1d2d1d", color: "white" }}
                >
                  <option value="default">Configuración general</option>
                  {subscriptionsForOptions.map(({ subscription, text }) => (
                    <option key={subscription} value={subscription}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedSubscription !== "default" && (
                <>
                  <Form.Group>
                    <WideBadge variant="secondary" style={{ marginBottom: 0 }} forwardedAs="label">
                      <input
                        type="checkbox"
                        checked={formik.values.subscriptionConfigs[selectedSubscription] === undefined}
                        onChange={(event) => onUseDefaultSubscriptionConfigChange(event, selectedSubscription)}
                      />{" "}
                      Aplicar la configuración general
                    </WideBadge>
                  </Form.Group>
                  {(() => {
                    const copyableSubscriptions = subscriptionsForOptions.filter(
                      ({ subscription }) =>
                        selectedSubscription !== subscription && // do not show the same subscription
                        formik.values.subscriptionConfigs[subscription] // only show subscriptions with configs
                    );
                    if (copyableSubscriptions.length === 0) return null;
                    let selectedSubscriptionToCopy = formik.values.subscriptionToCopy;
                    // if the selected subscription to copy is not in the subscriptions to copy, select a valid one
                    if (!copyableSubscriptions.find(({ subscription }) => subscription === selectedSubscriptionToCopy))
                      selectedSubscriptionToCopy = copyableSubscriptions[0].subscription;
                    return (
                      <Form.Group>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <Button
                              variant="primary"
                              onClick={() =>
                                copySubscriptionConfiguration(selectedSubscription, selectedSubscriptionToCopy, formik)
                              }
                            >
                              Copiar configuración desde
                            </Button>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            label="Copiar configuración desde:"
                            name="subscriptionToCopy"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={selectedSubscriptionToCopy}
                          >
                            {copyableSubscriptions.map(({ subscription, text }) => (
                              <option key={subscription} value={subscription}>
                                {text}
                              </option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      </Form.Group>
                    );
                  })()}
                </>
              )}

              <ConfigureValuebetFormSubscription subscription={selectedSubscription} />
              </> }
              <ConfigureValuebetSettings ref={settingsRef} {...{closeModal, formik, formLoading, setFormLoading}} />
            </fieldset>
          </Form>
        );
      }}
    </Formik>
  );
});
