import styled, {css} from "styled-components";
import Badge from "react-bootstrap/Badge";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";

export const ConfigureModal = styled(Modal)`
  .modal-content {
    font-family: 'Montserrat';
    background-color: #e1e9e5;
    color: #1d2d1d;

  }

  .modal-dialog {
    max-width: 900px;
    width: 100%;
  }

  .modal-header {
    background-color: #e1e9e5;
    color: #1d2d1d;
  }

  .close {
    color: #1d2d1d;
  }
`;

export const SeparatorWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #1d2d1d;

  .form-label {
    margin: 0 10px;
  }

  hr {
    flex: 1;
    background-color: rgb(68, 68, 68);
    height: 1px;
    border: none;
  }
`;

export const Fieldset = styled.fieldset`
  ${({hide}) => hide && css`display: none;`};
  ${({disabled}) => disabled && css`
    color: gray;
    & input,select,label.badge {
      cursor: not-allowed;
    }
    `
  };
`;

export const BookieBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  flex-grow: 1;
  width: 100%;

  border: ${(props) => !props.checked ? "1px solid #1d2d1d" : "1px solid white"};
  background-color: ${(props) => props.checked ? (props.isReference ? "#350F87" : "#208064") : "rgb(254, 254, 244)"};
  color: ${(props) => !props.checked ? "#1d2d1d" : "white"};

  ${({hide}) => hide && css`display: none;`};
  ${({clickable}) => clickable !== false && css`
    cursor: pointer;
    input[type=checkbox] {
      cursor: pointer;
    }
    `
  };
  
  & button.close {
    top: 4px;
    right: 5px;
    margin-left: 5px;
    height: 14px;
    line-height: 14px;
  }
`;

export const WideBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;

  border: ${(props) => !props.checked ? "1px solid #1d2d1d" : "1px solid white"};
  background-color: ${(props) => props.checked ? (props.isReference ? "#350F87" : "#208064") : "rgb(254, 254, 244)"};
  color: ${(props) => !props.checked ? "#1d2d1d" : "white"};

  ${({hide}) => hide && css`display: none;`};
  ${({clickable}) => clickable !== false && css`
    cursor: pointer;
    input[type=checkbox] {
      cursor: pointer;
    }
    `
  };
  
  & button.close {
    top: 4px;
    right: 5px;
    margin-left: 5px;
    height: 14px;
    line-height: 14px;
  }
`;

export const LabelSelectAll = styled.label`
  font-size: 100%;
  margin: 0px 10px;
  background-color: rgb(68, 68, 68);
  padding: 0.6em;
  border-radius: 4px;
  line-height: 1em;
  user-select: none;
  cursor: pointer;
  background: white;
  color: #1d2d1d;
  input[type=checkbox] {
    cursor: pointer;
  }
`;

export const DropdownButtonAppended = styled(DropdownButton)`
  > button.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const BookiesContainer = styled.div`
  background: #1d2d1d;
  padding: 10px;
  border-radius: 5px;
  color: white;

`;

export const LabelsContainer = styled.div`
  background: #1d2d1d;
  padding: 10px;
  border-radius: 5px;
  color: white;
  margin-bottom: 10px;
`;

export const BookieSearchContainer = styled.div`
  margin: 15px 0px;
  width: 200px;


`

export const BookieListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 5px;
`;

export const ClonesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #82a880;
  // padding: 5px 0px 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  flex-grow: 1;
`;

export const MainBookieContainer = styled.div`
  background: #3d593c;
  padding: 10px 9px 0px 10px;
  border-radius: 5px;
  flex-grow: 1;

  ${props => props.nonClone && `margin-bottom: 5px;`}

`;

export const Clone2Container = styled.div`
  display: flex;
  gap: 5px;

  padding: 10px 9px 0px 10px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  
`;