import styled from 'styled-components';

export const StatsSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  background-color: white; // Assuming this is the background color you want
  padding: 40px 0 20px 0px;
  gap: 5px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1455px) {
    width: 100%;
    padding: 20px;
  }

`;

export const Container = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 140px;
  width: 100%;
  max-width: 1600px;

  color: #111E12;

  @media (max-width: 728px) {
    flex-direction: column;
    align-items: center;
    height: unset;
  }

  @media only screen and (max-width: 1455px) and (min-width: 728px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; // adjust this to control the space between the items
    height: unset;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 100%;

  text-align: center;
  font-family: Montserrat;
  letter-spacing: -0.5px;

  @media (max-width: 1455px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  font-weight: 800;
  letter-spacing: -1.5px;
  display: flex;
  flex-direction: column;

  align-self: center;
  justify-content: center;
  height: 90px;

`;

export const Data = styled.h3`
  height: 50px;

`;