import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './Check.styled';
import { ConfirmationContainer, MessageContainer, ConfirmationMessage, LogoContainer } from "./Check.styled";
import Logo from "../../static/logo_white.svg";

export const Check = (props) => {
  const { t } = useTranslation();
    
        return ( 
            <ConfirmationContainer>
                <MessageContainer>
                    <ConfirmationMessage>
                        <p>
                            <Trans >
                                {t('checkLine1')}
                            </Trans>
                        </p>
                        <p>
                            <Trans >
                                {t('checkLine2')}
                            </Trans>
                        </p>
                    </ConfirmationMessage>
                </MessageContainer>
                <LogoContainer onClick={() => props.history.push('/')}>
                    <img src={Logo} alt="Logo" style={{ cursor: "pointer" }} />
                </LogoContainer>
            </ConfirmationContainer>
 );
}