import styled from 'styled-components';
import pattern from '../../../static/home/pattern.png';

export const NumbersSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ABCDDF; // Assuming this is the background color you want
  padding: 80px 0 80px 0px;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  background-image: url(${pattern});
  background-size: ${Math.floor(Math.random() * 200 + 200)}px ${Math.floor(Math.random() * 200 + 200)}px;
  background-position: ${Math.random() < 0.5 ? 'center' : 'top left'};

  @media (max-width: 1455px) {
  @media (max-width: 1455px) {
    width: 100%;
    padding: 20px;
  }

`;


export const Container = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  height: 100%;
  width: 100%;
  max-width: 1600px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 23px;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  letter-spacing: -1px;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  `;

export const Subtitle = styled.h3`
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  letter-spacing: -1px;
  color: #ABCDDF;
  font-weight: 600;
  font-family: 'Denton';
  text-align: center;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;