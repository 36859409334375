import styled from 'styled-components';
import pattern from '../../../static/home/pattern.png';

export const ArbsSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ABCDDF; // Assuming this is the background color you want
  padding: 80px 0 0px 0px;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  background-image: url(${pattern});
  background-size: ${Math.floor(Math.random() * 200 + 200)}px ${Math.floor(Math.random() * 200 + 200)}px;
  background-position: ${Math.random() < 0.5 ? 'center' : 'top left'};

  @media (max-width: 1455px) {
  @media (max-width: 1455px) {
    width: 100%;
    padding: 20px;
  }

`;


export const Container = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  height: 100%;
  width: 100%;
  max-width: 1600px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 23px;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  letter-spacing: -1px;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

export const Subtitle = styled.h3`
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  letter-spacing: -1px;
  color: #ABCDDF;
  font-weight: 600;
  font-family: 'Denton';
  text-align: center;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

export const MatchOverview = styled.div`
  display: flex;
  padding-left: 20px;

  @media only screen and (max-width: 700px) {
    padding-left: 5px;
  }
`

export const Match = styled.div`
  font-size: 18px;
  font-weight: 800;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const ProfitBadgeText = styled.span`
  font-size: medium;
  margin-left: 5px;
  font-size: 18px;
  background-color: #E9FD8C;
  color: #1a2e1a;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const SportIcon = styled.img`
  padding-right: 5px;
  height: 18px;
  filter: invert(13%) sepia(29%) saturate(139%) hue-rotate(182deg) brightness(97%) contrast(95%);

  @media (max-width: 768px) {
    height: 14px;
  }
`

export const BetButton = styled.button`
  position: absolute;
  right: 10px;
`

export const BetDate = styled.div`
    display: flex;
    padding-left: 20px;
    align-items: center;
    margin-top: 5px;

    @media only screen and (max-width: 700px) {
      padding-left: 5px;
    }
`

export const ClockIcon = styled.i`
  margin-right: 5px;
`

export const BetCardTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  padding-bottom: 30px;
`

export const Column = styled.div`
  height: ${props => props.small ? 'auto' : 'fit-content'}
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 60px;
`

export const BetCardTitle = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;
  font-size: 20px;
  font-weight: 800;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const BetCardRow = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 300;
  flex-grow: ${props => props.small ? 1 : 0};

  .status {
    margin-left: 5px;
  }

  .status-circle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 3px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`

export const ProfitColumn = styled.div``

export const Profit = styled.div`
  display: flex;
  justify-content: center;
`

export const BetCardProfit = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
`

export const RowContentContainer = styled.div`
  height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around
`
