import styled from "styled-components";
import { Form } from "react-bootstrap";

export const PageContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;
  font-family: 'Montserrat';

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }

`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  gap: 15px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const Title = styled.h1`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;
  padding-bottom: 30px;
  width: 100%;

  font-size: 30px;
  align-self: flex-start;
`;

export const HomeHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  @media only screen and (max-width: 700px) {
    padding-left: 0px;
  }
`

export const DataMainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 15px;
  
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 700px) {
    padding-left: 0px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  background-color: #192619;
  padding: 20px;
  border-radius: 10px;
  height: 140px;
  min-width: 150px;

  @media only screen and (max-width: 900px) {
    width: calc(50% - 7.5px);
  }

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const DataContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const DataContainerTitle = styled.h1`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;
  font-size: 22px;
  text-align: center;
  color: #758b77;
`;

export const DataContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #192619;
  border-radius: 5px;
`

export const Value = styled.div`
  font-size: 30px;
  font-weight: bold;
`

export const UserAlertBadge = styled.div`
  background: #192619;
  margin: 0px;
  border-radius: 10px;
`

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #111E12;
  padding: .4rem 15px;
  border-radius: 10px;
  background: #192619;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 0px;
    margin-top: 5px;
  }
`

export const MoneyChangeBadge = styled.span`
  padding: 5px;
  font-size: small;
  margin-left: 5px;
  background-color: ${props => props.pendingPayments >= 0 ? "#94E5AB" : "#A33"};
  color: ${props => props.pendingPayments >= 0 ? "#192619" : "white"};
`

export const ActivePicker = styled.div`
  width: fit-content;
  flex-wrap: nowrap !important;
  background: #192619;
  border-radius: 5px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    margin: 0px;
    padding: 15px 10px 10px 15px;
  }

  li{
    flex-grow: 1;
  }

  > li > .nav-link{
    padding: 0.5rem 1rem;
  }
`

export const ActivePickerItem = styled.li`
  .active {
    background-color: #E9FD8C !important;
    color: #1d2d1d !important;
    font-weight: bold;
  }

  :hover {
    font-weight: bold;
    border-radius: 5px;
  }
`

export const ActivePickerItemLink = styled.a``

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #192619;
  padding: 4.5px;
  border-radius: 5px;
  padding-right: 10px;
`

export const NameSearchContainer = styled.div`
  width: 200px;

  input {
    background-color: #192619;
    border: 0px;
    color: white;

    ::placeholder {
      color: white;
      opacity: 0.6;
    }
  }
`;

export const ChartContainerParent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .recharts-brush-texts > .recharts-text {
    fill: #E9FD8C !important;
    font-weight: bold;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  background-color: #192619;
  padding: 20px;
  border-radius: 10px;

  h2 {
    color: white;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const ChartContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`

export const TooltipContainer = styled.div`
  background-color: #111E12;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 250px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const TooltipItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 5px;
`

export const TooltipLabel = styled.div`
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
`

export const TooltipItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const TooltipIcon = styled.div`
  background-color: ${props => props.color};
  width: 2px;
  height: 10px;
  border-radius: 2px;
`

export const TooltipText = styled.div`
  color: white;
`

export const TooltipValue = styled.div`
  color: #b8dcef;
  font-weight: bold;
`

export const TooltipSeparator = styled.div`
  color: white;
  width: 100%;
  height: 1px;
  background-color: rgb(255, 255, 255, 0.1);
  margin-top: 5px;

`

export const TooltipTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3px;
`

export const TooltipTotal = styled.div`
  color: white;
  font-weight: bold;
  font-size: 15px;
`

export const TooltipTotalValue = styled.div`
  color: #e9fd8c;
  font-weight: bold;
  font-size: 15px;
`

export const DateRangeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 7px;
  border-radius: 10px;
  background-color: #192619;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const DateFilterContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const DateFilterButton = styled.button`
  background-color: ${props => props.active ? '#E9FD8C' : '#192619'};
  color: ${props => props.active ? '#1d2d1d' : 'white'};
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => props.active ? '#E9FD8C' : '#354236'};
  }

  @media (max-width: 768px) {
    flex: 1;
    white-space: nowrap;
  }
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 120px;
  }

  .react-datepicker__input-container input {
    background-color: #192619;
    border: 1px solid #354236;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;

    &::placeholder {
      color: #758b77;
    }
  }

  .react-datepicker {
    background-color: #192619;
    border: 1px solid #758b77;
  }

  .react-datepicker__header {
    background-color: #192619;
    border-bottom: 1px solid #758b77;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: white;
  }

  .react-datepicker__day:hover {
    background-color: #758b77;
  }

  .react-datepicker__day--selected {
    background-color: #E9FD8C;
    color: #1d2d1d;
  }

  @media (max-width: 768px) {
    width: 100%;
    
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;